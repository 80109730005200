import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CsvDialogComponent } from '../../dialogs/csv-dialog/csv-dialog.component';
import { TableViewerComponent } from '../../dialogs/table-viewer/table-viewer.component';
import { uncalculatableTime } from '../../_helpers';
import { Data } from '../../_providers';
import { Type1DataSource } from './type-1.datasource';


@Component({
  selector: 'app-type-1',
  templateUrl: './type-1.component.html',
  styleUrls: ['./type-1.component.css', '../table-common.css']
})
export class Type1Component implements OnInit, OnDestroy {
  displayedColumns = ['driver', 'date', 'day', 'ccStart', 'ddcStart', 'ccFinish', 'ddcFinish', 'amVariance', 'pmVariance', 'totalVariance', 'comments'];
  editableColumns = ['driver', 'date', 'ccStart', 'ddcStart', 'ccFinish', 'ddcFinish', 'comments'];

  dataSource: Type1DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  readonly noCalcTime = uncalculatableTime;

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) {
    console.log(this.noCalcTime);
  }

  ngOnInit() {
    this.dataSource = new Type1DataSource(this.data, this.inputData.table);
    if (this.dataSource) {
      this.dataSource.loadData();
    }
    
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDate(index: number, newDate: Date) {
    this.dataSource.updateDate(index, newDate);
  }

  updateCcStart(index: number, timestring) {
    this.dataSource.updateCcStart(index, timestring);
  }

  // updateCcStart(event) {
  //   console.log(event.target.value);
  //   var timeString: string = event.target.value;
  //   var hourMinSplit = timeString.split(':');
  //   console.log(hourMinSplit);
  //   if (hourMinSplit.length === 2)
  //   {
  //     var d = new Date('1970-1-1');
  //     d.setHours(parseInt(hourMinSplit[0]),parseInt(hourMinSplit[1]));
  //     console.log(d);
  //
  //   }else{
  //     console.error('time format came back wrong couldn\'t split into minue and hour');
  //     console.error(event);
  //     console.error(timeString)
  //     console.error(hourMinSplit);
  //   }
  //
  //
  // }

  updateDdcStart(index: number, timestring) {
    this.dataSource.updateDdcStart(index, timestring);
  }

  updateCcFinish(index: number, timestring) {
    this.dataSource.updateCcFinish(index, timestring);
  }

  updateDdcFinish(index: number, timestring) {
    this.dataSource.updateDdcFinish(index, timestring);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }

  onSelectFile(event) {
    var fileName = event.target.files[0].name;

    const extn = fileName.split(".");
    console.log(extn[1]);

    if (event.target.files && extn[1] === 'csv') {
      for (let i = 0; i < event.target.files.length; i++) {

        const reader = new FileReader();
        reader.readAsText(event.target.files[i]); // read file as data ur
        reader.onload = (FileData) => {
          // console.log(FileData);
          let data = FileData.target['result'];
          // console.log(data);
          // console.log(DataFuntions.CSVToArray(data));
          // console.log(DataFuntions.CSVToObejectArray(data));

          const questionID = this.data.currentQuestionID;
          const dialogRef = this.dialog.open(TableViewerComponent, {
            data: {
              fileData: data, /*this.fileGroups[index]*/
              tableHeaders: this.editableColumns
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            // console.log(result);
            if (result != null) {
              this.dataSource.replaceData(result);
            }

          });
        };
      }
    } else {
      const csvDialog = this.dialog.open(CsvDialogComponent, {
      });
    }
    event.target.value = ''; // resetting value to allow reuse of same file
  }

  onKey() {
    this.dataSource.debounceLoad();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
